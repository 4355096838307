import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { deleteUser, reauthenticateWithCredential, EmailAuthProvider, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { deleteDoc, doc } from "firebase/firestore";

function ConfirmDelete() {
  const [password, setPassword] = useState(""); // For email/password reauthentication
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false); // Track successful account deletion
  const navigate = useNavigate();

  const handleReauthentication = async () => {
    const user = auth.currentUser;

    if (user.providerData[0]?.providerId === "google.com") {
      // Reauthenticate Google account users
      try {
        const provider = new GoogleAuthProvider();
        await signInWithPopup(auth, provider);
        return true; // Reauthentication successful
      } catch (error) {
        console.error("Google reauthentication error:", error);
        setError("Failed to reauthenticate your Google account. Please try again.");
        return false;
      }
    } else {
      // Reauthenticate email/password users
      try {
        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential);
        return true; // Reauthentication successful
      } catch (error) {
        console.error("Reauthentication error:", error);
        setError("Invalid password or an error occurred. Please try again.");
        return false;
      }
    }
  };

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    setError("");

    const isReauthenticated = await handleReauthentication();

    if (isReauthenticated) {
      try {
        const user = auth.currentUser;

        // Delete Firestore document
        const userDoc = doc(db, "users", user.uid);
        await deleteDoc(userDoc);

        // Delete Firebase user
        await deleteUser(user);

        // Set success state
        setSuccess(true);
      } catch (err) {
        console.error("Account deletion error:", err);
        setError("An error occurred while deleting your account. Please try again.");
      }
    }
  };

  if (success) {
    // Show success message if account is successfully deleted
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white px-6">
        <div className="w-full max-w-md bg-gray-700 p-6 rounded-lg shadow-lg text-center">
          <h1 className="text-3xl font-bold mb-4">Account Deleted</h1>
          <p className="mb-6 text-gray-300">
            Your account has been successfully deleted. We're sorry to see you go!
          </p>
          <button
            onClick={() => navigate("/")}
            className="w-full bg-indigo-500 text-white py-2 rounded hover:bg-indigo-700 transition-all"
          >
            Return to Home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-800 text-white px-6">
      <div className="w-full max-w-md bg-gray-700 p-6 rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-4 text-center">Confirm Account Deletion</h1>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        {auth.currentUser.providerData[0]?.providerId !== "google.com" && (
          <form onSubmit={handleDeleteAccount}>
            <label className="block text-gray-300 mb-2">Enter your password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-3 py-2 rounded-lg bg-gray-600 text-white mb-4"
              required
            />
            <button
              type="submit"
              className="w-full bg-red-700 text-white py-2 rounded hover:bg-red-900 transition-all"
            >
              Confirm and Delete
            </button>
          </form>
        )}
        {auth.currentUser.providerData[0]?.providerId === "google.com" && (
          <button
            onClick={handleDeleteAccount}
            className="w-full bg-red-700 text-white py-2 rounded hover:bg-red-900 transition-all"
          >
            Reauthenticate with Google and Delete
          </button>
        )}
        <button
          onClick={() => navigate("/profile")}
          className="w-full bg-gray-500 text-white py-2 mt-2 rounded hover:bg-gray-700 transition-all"
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default ConfirmDelete;
