import React, { useState, useEffect } from "react";
import { toPng } from "html-to-image";
import LadderPredictionForm from "../components/LadderPredictionForm";
import { db } from "../firebase"; // Firebase setup
import { doc, setDoc, getDoc } from "firebase/firestore"; // Firestore functions
import { useAuth } from "../AuthContext"; // Assuming you use an Auth context
import { ref, uploadString, getDownloadURL } from "firebase/storage"; // Firebase Storage imports
import { storage } from "../firebase"; // Import your Firebase storage setup

const fetchSavedPrediction = async (userId, setSavedPrediction, setIsLoading) => {
  try {
    const predictionRef = doc(db, "users", userId, "predictions", "currentPrediction");
    const predictionDoc = await getDoc(predictionRef);
    if (predictionDoc.exists()) {
      const data = predictionDoc.data();
      setSavedPrediction({
        displayName: data.displayName,
        predictions: data.teams,
      });
    } else {
      console.log("No saved prediction found.");
    }
  } catch (error) {
    console.error("Error fetching saved prediction:", error);
  } finally {
    setIsLoading(false); // Stop loading regardless of success or failure
  }
};

function MyPredictions() {
  const { user } = useAuth(); // Get the logged-in user
  const userId = user ? user.uid : "guest"; // Use a fallback if not logged in
  const [savedPrediction, setSavedPrediction] = useState(null);
  const [isEditing, setIsEditing] = useState(false); // Controls whether editing is active
  const [isSaving, setIsSaving] = useState(false); // Prevents glitch during saving
  const [isLoading, setIsLoading] = useState(true); // Loading state

  // Function to save prediction to Firestore
  const savePredictionToFirestore = async (userId, prediction) => {
    try {
      setIsSaving(true); // Start saving process
  
      const predictionRef = doc(db, "users", userId, "predictions", "currentPrediction");
  
      console.log("Saving prediction to Firestore:", prediction);
  
      await setDoc(
        predictionRef,
        {
          displayName: prediction.displayName, // Ensure the displayName is updated
          teams: prediction.predictions, // Keep the predictions array
          updatedAt: new Date().toISOString(), // Optional: track the update time
        },
        { merge: true } // Ensures partial updates
      );
  
      console.log("Prediction updated successfully in Firestore!");
  
      // Update local state
      setSavedPrediction(prediction);
      console.log("Updated savedPrediction state:", prediction);
  
      setIsEditing(false); // Exit editing mode
    } catch (error) {
      console.error("Error updating prediction:", error);
    } finally {
      setIsSaving(false); // Reset saving state
    }
  };
  
  

  // Fetch the saved prediction when the component mounts
  useEffect(() => {
    if (userId !== "guest") {
      fetchSavedPrediction(userId, setSavedPrediction, setIsLoading);
    } else {
      setIsLoading(false); // No need to load if no user is logged in
    }
  }, [userId]);

  // Share prediction to Twitter
  const handleShare = async () => {
    const ladderElement = document.getElementById("ladder-snapshot");
    if (!ladderElement) {
      console.error("Ladder element not found");
      return;
    }
  
    try {
      // Generate image from the ladder element
      const image = await toPng(ladderElement, { cacheBust: true });
  
      // Upload the image to Firebase Storage
      const imageName = `snapshots/${userId}_${Date.now()}.png`;
      const imageRef = ref(storage, imageName);
  
      await uploadString(imageRef, image, "data_url");
      console.log(`Image uploaded to Firebase Storage: ${imageName}`);
  
      // Get the public URL of the uploaded image
      const imageUrl = await getDownloadURL(imageRef);
      console.log(`Image URL: ${imageUrl}`);
  
      // Create Twitter share URL
      const twitterText = `Check out my AFL ladder prediction! Think you can do better? Make yours at Aussie Rules Madness: https://aussierulesmadness.com`;
      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        twitterText
      )}&url=${encodeURIComponent(imageUrl)}`;
  
      // Open Twitter share link in a new tab
      window.open(twitterUrl, "_blank");
    } catch (error) {
      console.error("Error during sharing to Twitter:", error);
    }
  };
  

  // Render loading spinner
  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-indigo-900 via-gray-800 to-gray-900 text-white">
        <div className="flex flex-col items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-indigo-500 border-solid"></div>
          <p className="mt-4 text-lg font-semibold">Loading your predictions...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="pt-20 min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-indigo-900 via-gray-800 to-gray-900 text-white">
      <div className="bg-gray-800 shadow-lg rounded-lg p-8 max-w-3xl w-full mb-8 mt-6">
        <h1 className="text-3xl font-bold text-center mb-6">My Ladder Prediction</h1>
        {isSaving ? (
          <p className="text-center">Saving your prediction...</p>
        ) : isEditing ? (
          <LadderPredictionForm
            onSave={(prediction) => savePredictionToFirestore(userId, prediction)}
            initialPrediction={savedPrediction}
          />
        ) : savedPrediction ? (
          <>
            <div
              id="ladder-snapshot"
              className="p-4 bg-gradient-to-r from-indigo-700 to-indigo-900 rounded-lg shadow-lg"
            >
              <h2 className="text-xl font-bold text-center text-white mb-2">
                {savedPrediction.displayName || "Your Prediction"}
              </h2>

              <ul className="space-y-1">
                {savedPrediction.predictions.map((team, index) => (
                  <li
                    key={team.name}
                    className="flex items-center justify-between bg-indigo-600 text-white rounded-md p-1 shadow-sm"
                  >
                    <div className="flex items-center space-x-2">
                      {/* Circular container for logo */}
                      <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center overflow-hidden border border-indigo-400">
                        <img
                          src={team.logo}
                          alt={team.name}
                          className="object-contain w-6 h-6"
                        />
                      </div>
                      {/* Team name */}
                      <span className="text-sm font-semibold">{team.name}</span>
                    </div>
                    {/* Ranking number */}
                    <span className="text-sm font-bold">{index + 1}</span>
                  </li>
                ))}
              </ul>
            </div>


            <div className="flex justify-center mt-4 space-x-4">
              <button
                onClick={() => setIsEditing(true)}
                className="bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-700"
              >
                Edit Prediction
              </button>
              <button
                onClick={handleShare}
                className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-700"
              >
                Share to Twitter
              </button>
            </div>
          </>
        ) : (
          <div
            onClick={() => setIsEditing(true)}
            className="text-center p-6 bg-indigo-700 hover:bg-indigo-800 rounded-lg cursor-pointer"
          >
            <span className="text-xl font-semibold">+ Create New Prediction</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyPredictions;
