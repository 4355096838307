import FingerprintJS from "@fingerprintjs/fingerprintjs";

// Service to generate a fingerprint with filtered metadata
export async function getFullFingerprint() {
  const fp = await FingerprintJS.load();
  const result = await fp.get();

  // Simplify metadata and handle nested arrays
  const filteredMetadata = {
    userAgent: result.components.userAgent?.value || "unknown",
    platform: result.components.platform?.value || "unknown",
    languages: Array.isArray(result.components.languages?.value)
      ? result.components.languages.value.join(", ")
      : result.components.languages?.value || "unknown",
    timezone: result.components.timezone?.value || "unknown",
    screenResolution: result.components.screenResolution?.value?.join("x") || "unknown",
    vendor: result.components.vendor?.value || "unknown",
    deviceMemory: result.components.deviceMemory?.value || "unknown",
    hardwareConcurrency: result.components.hardwareConcurrency?.value || "unknown",
    colorDepth: result.components.colorDepth?.value || "unknown",
  };

  return {
    visitorId: result.visitorId,
    metadata: filteredMetadata,
  };
}

// Log fingerprint to backend
export async function logFingerprintToBackend(userId) {
  try {
    const { visitorId, metadata } = await getFullFingerprint();

    const response = await fetch("http://localhost:2000/api/fingerprint", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ visitorId, userId, metadata }),
    });

    if (!response.ok) {
      throw new Error("Failed to log fingerprint");
    }
    console.log("Fingerprint logged successfully:", { visitorId, userId });
  } catch (error) {
    console.error("Error logging fingerprint to backend:", error);
  }
}
