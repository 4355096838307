import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { confirmPasswordReset } from "firebase/auth";
import { auth } from "../firebase"; // Adjust path to your Firebase configuration

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get("oobCode"); // Extract the reset code from the URL
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();

  const handlePasswordReset = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      // Use Firebase to confirm the password reset
      await confirmPasswordReset(auth, oobCode, newPassword);
      setSuccess("Password has been reset successfully. You can now log in.");
      setError("");

      // Redirect to login after a delay
      setTimeout(() => navigate("/auth"), 3000);
    } catch (err) {
      console.error("Error resetting password:", err);
      setError("Failed to reset password. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-800 text-white px-6">
      <div className="w-full max-w-md bg-gray-700 p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-4 text-center">Set New Password</h1>
        {error && <p className="text-red-400 text-center">{error}</p>}
        {success && <p className="text-green-400 text-center">{success}</p>}
        <form onSubmit={handlePasswordReset}>
          <div className="mb-4">
            <label className="block text-gray-300">New Password</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
              className="w-full p-2 rounded bg-gray-600 text-white"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-300">Confirm New Password</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full p-2 rounded bg-gray-600 text-white"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-500 text-white py-2 rounded hover:bg-indigo-700 transition-all"
          >
            Reset Password
          </button>
        </form>
        <button
          onClick={() => navigate("/auth")}
          className="w-full mt-4 bg-gray-500 text-white py-2 rounded hover:bg-gray-600 transition-all"
        >
          Back to Login
        </button>
      </div>
    </div>
  );
}

export default ResetPassword;
