import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import MyPredictions from "./pages/MyPredictions";
import Profile from "./pages/Profile";
import AboutUs from "./pages/AboutUs";
import AuthPage from "./pages/AuthPage";
import CompetitionRules from "./pages/CompetitionRules"; // Adjust the path if needed
import CompleteProfile from "./pages/CompleteProfile";
import VerifyEmail from "./pages/VerifyEmail"; // Added the VerifyEmail page
import PasswordReset from "./pages/PasswordReset"; // Import the PasswordReset page
import { AuthProvider } from "./AuthContext"; // Ensure this is imported correctly
import ProtectedRoute from "./ProtectedRoute";
import EmailActionHandler from "./pages/EmailActionHandler"; // Adjust the path if needed
import CookieConsent from "react-cookie-consent";
import ForgotPassword from "./pages/ForgotPassword"; // Import the new ForgotPassword page
import ChangePassword from "./pages/ChangePassword"; // Import the ChangePassword page
import ResetPassword from "./pages/ResetPassword"; // Reset password page
import ConfirmDelete from "./pages/ConfirmDelete";
import TermsAndConditions from "./pages/TermsAndConditions"; // Import your T&C component
import Football3D from './components/Football3D';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import FingerprintPage from "./pages/FingerprintPage";





function App() {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <CookieConsent
          location="bottom"
          buttonText="Got it!"
          style={{
            background: "linear-gradient(90deg, #1e1e2f, #2c2c44)", // Gradient background
            color: "#ffffff", // Light text for readability
            fontSize: "14px", // Font size matches website's typography
          }}
          buttonStyle={{
            background: "linear-gradient(90deg, #4b6cb7, #182848)", // Matches button style
            color: "#ffffff", // Button text color
            fontSize: "14px",
            padding: "10px 20px",
            borderRadius: "5px", // Rounded corners
            border: "none",
            cursor: "pointer",
          }}
          expires={150}
          onAccept={() => {
            // Initialize Google Analytics after user consent
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              window.dataLayer.push(arguments);
            }
            gtag("js", new Date());
            gtag("config", "G-2VLKQE7QVP"); // Replace with your actual GA Measurement ID
          }}
        >
          This website collects data to improve your experience. See our{" "}
          <a
            href="/about-us"
            style={{
              color: "#4b8ed3", // Link color to match site theme
              textDecoration: "underline",
            }}
          >
            Privacy Policy
          </a>.
        </CookieConsent>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          <Route path="/auth" element={<AuthPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/complete-profile" element={<CompleteProfile />} />
          <Route path="/comp-rules" element={<CompetitionRules />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/__auth/action" element={<EmailActionHandler />} />
          <Route path="/password-reset" element={<PasswordReset />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/confirm-delete" element={<ConfirmDelete />} />
          <Route path="/test-football" element={<Football3D />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} /> {/* Terms page */}
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> {/* Terms page */}
          <Route path="/fingerprint" element={<FingerprintPage />} />


          {/* Enter new password */}

          {/* Added Password Reset Route */}

          {/* Protected Routes */}
          <Route
            path="/my-predictions"
            element={
              <ProtectedRoute>
                <MyPredictions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
