import React from 'react';

function AboutUs() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-black text-white px-4 sm:px-6 md:px-8">
      {/* Title */}
      <h2 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-purple-500 mb-6 text-center animate-fadeIn">
        About Us
      </h2>

      {/* Description */}
      <p className="text-base sm:text-lg md:text-xl max-w-lg md:max-w-3xl text-center leading-relaxed text-gray-300">
        Aussie Rules Madness is dedicated to bringing 
        AFL fans together through an exciting ladder prediction challenge.
        <br />
        <br />
        Our goal is to create a platform where fans can showcase their knowledge, have fun, and <span className="text-indigo-400 font-semibold">win big! </span> 
         Join us and become part of a new Aussie tradition.
      </p>
    </div>
  );
}

export default AboutUs;
