import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext"; // Import AuthContext to check authentication status
import Football3D from "../components/Football3D"; // Import the Football3D component

function Home() {
  const navigate = useNavigate();
  const { user } = useAuth(); // Access the current user from AuthContext

  const handleGetStarted = () => {
    if (user) {
      // If user is signed in, navigate to the "My Predictions" page
      navigate("/my-predictions");
    } else {
      // If user is not signed in, navigate to the Auth page
      navigate("/auth");
    }
  };

  return (
    <section className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-800 via-gray-900 to-black text-white px-6 relative">
      {/* Background Image with Blur */}
      <div
        className="absolute inset-0 bg-cover bg-center filter blur-sm opacity-60"
        style={{ backgroundImage: "url('/path-to-your-image/logos-with-mcg.jpeg')" }}
      ></div>

      {/* Content */}
      <div className="relative z-10 text-center">
        {/* Animated Heading */}
        <h1 className="text-4xl md:text-6xl font-extrabold mb-4 animate-fadeIn">
          Welcome to <span className="text-purple-500">Aussie Rules Madness</span>
        </h1>

        {/* Enhanced Typography for Subheading */}
        <p className="text-lg md:text-xl mb-6 max-w-2xl mx-auto text-gray-300">
          Join the ultimate AFL ladder prediction challenge and stand a chance to win <span className="text-yellow-400 font-semibold">up to $100k</span>!
        </p>

        {/* Clickable 3D Football */}
        <div className="flex flex-col items-center">
          <div
            onClick={handleGetStarted} // Navigate to the appropriate page when clicked
            className="cursor-pointer mb-2 transform transition-transform hover:scale-110 hover:rotate-12"
            style={{
              width: "250px", // Size the canvas to fit as a button
              height: "250px",
            }}
          >
            <Football3D /> {/* Render the Football3D component */}
          </div>

          {/* Subtle Hint with Animation */}
          <p className="text-sm text-gray-400 animate-bounce text-center">
            Click the football to get started!
          </p>
        </div>
      </div>
    </section>
  );
}

export default Home;
