import React, { useState } from "react";
import { getFullFingerprint } from "../utils/FingerprintService";
import { getAuth } from "firebase/auth";

function FingerprintPage() {
  const [fingerprint, setFingerprint] = useState(null);
  const [responseMessage, setResponseMessage] = useState("");

  const logFingerprint = async () => {
    try {
      const { visitorId, metadata } = await getFullFingerprint();
  
      const auth = getAuth();
      const user = auth.currentUser;
  
      if (!user) {
        throw new Error("User is not logged in");
      }
  
      const requestBody = {
        visitorId,
        userId: user.uid,
        metadata, // Simplified metadata
      };
  
      console.log("Request Body:", requestBody); // Log the request body for debugging
  
      const response = await fetch("http://localhost:2000/api/fingerprint", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
  
      const responseData = await response.text();
      setFingerprint(visitorId);
      setResponseMessage(responseData);
    } catch (error) {
      console.error("Error logging fingerprint:", error);
      setResponseMessage("Error logging fingerprint");
    }
  };
  
  return (
    <div style={{ padding: "20px" }}>
      <h1>Fingerprint Logger</h1>
      <p>Click the button below to log your fingerprint.</p>
      <button onClick={logFingerprint}>Log Fingerprint</button>

      {fingerprint && <p>Generated Fingerprint: {fingerprint}</p>}
      {responseMessage && <p>Backend Response: {responseMessage}</p>}
    </div>
  );
}

export default FingerprintPage;
