import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  applyActionCode,
  verifyPasswordResetCode,
  getAuth,
} from "firebase/auth";
import { doc, setDoc, getFirestore } from "firebase/firestore";

function EmailActionHandler() {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loading state for actions
  const isProcessingRef = useRef(false); // Prevent duplicate processing
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const db = getFirestore();
  const auth = getAuth();

  // Function to update Firestore with the verification status
  const updateUserVerificationStatus = useCallback(
    async (user) => {
      try {
        const userDocRef = doc(db, "users", user.uid);
        await setDoc(userDocRef, { emailVerified: true }, { merge: true });
        console.log("User verification status updated in Firestore.");
      } catch (error) {
        console.error("Error updating Firestore:", error);
      }
    },
    [db]
  );

  useEffect(() => {
    const handleAction = async () => {
      const mode = searchParams.get("mode"); // Action type: verifyEmail, resetPassword, etc.
      const oobCode = searchParams.get("oobCode"); // One-time code for the action

      console.log("Mode:", mode, "oobCode:", oobCode); // Debugging
      if (!isProcessingRef.current && mode && oobCode) {
        isProcessingRef.current = true; // Prevent further processing
        setIsLoading(true); // Show loading spinner

        try {
          if (mode === "verifyEmail") {
            // Email Verification
            console.log("Processing email verification...");
            await applyActionCode(auth, oobCode); // Verify the action code
            console.log("Email verification applied successfully.");

            if (auth.currentUser) {
              await auth.currentUser.reload(); // Reload user to get updated emailVerified status
              if (auth.currentUser.emailVerified) {
                console.log("Email Verified:", auth.currentUser.emailVerified);
                setMessage("Your email has been verified successfully!");
                await updateUserVerificationStatus(auth.currentUser); // Update Firestore
                setTimeout(() => navigate("/my-predictions"), 3000); // Redirect
              } else {
                setError(
                  "Verification succeeded, but emailVerified status is not updated."
                );
              }
            } else {
              setError("No authenticated user found.");
            }
          } else if (mode === "resetPassword") {
            // Password Reset
            console.log("Processing password reset...");
            await verifyPasswordResetCode(auth, oobCode); // Verify the password reset code
            console.log("Password reset code verified successfully.");
            setMessage("Password reset code verified. Redirecting...");
            setTimeout(() => navigate(`/reset-password?oobCode=${oobCode}`), 3000); // Redirect to reset password page
          } else {
            setError("Unsupported action type.");
          }
        } catch (error) {
          console.error("Error handling action:", error);
          if (error.code === "auth/invalid-action-code") {
            setError(
              "The action link is invalid or has already been used."
            );
          } else {
            setError("Error processing action: " + error.message);
          }
        } finally {
          setIsLoading(false); // Hide loading spinner
        }
      }
    };

    handleAction();
  }, [searchParams, navigate, auth, updateUserVerificationStatus]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white px-6">
      <h2 className="text-3xl font-bold mb-4 text-center">Action Processing</h2>
      {isLoading && (
        <div className="mb-6">
          <div className="w-10 h-10 border-4 border-indigo-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}
      {message && <p className="text-green-500 text-center">{message}</p>}
      {error && <p className="text-red-500 text-center">{error}</p>}
      {error && (
        <div className="flex flex-col items-center mt-4 space-y-2">
          <button
            className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-700 transition-all"
            onClick={() => navigate("/auth")}
          >
            Back to Login
          </button>
          <button
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition-all"
            onClick={() => window.location.reload()}
          >
            Retry
          </button>
        </div>
      )}
    </div>
  );
}

export default EmailActionHandler;
