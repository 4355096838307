import React from "react";

function CompetitionRules() {
  return (
    <div className="pt-32 pb-32 min-h-screen bg-gray-800 text-white flex items-center justify-center px-4 sm:px-6 md:px-8">
      <div className="max-w-2xl sm:max-w-4xl text-center">
        <h1 className="text-3xl sm:text-4xl font-bold mb-6 sm:mb-8">
          Competition Rules
        </h1>
        <p className="mb-6 text-base sm:text-lg leading-relaxed">
          Welcome to the Aussie Rules Madness competition! Please read the rules
          below to understand how the competition works, who can enter, and how
          you can win the prize.
        </p>

        <div className="text-left">
          <h2 className="text-xl sm:text-2xl font-semibold mt-6">1. Overview</h2>
          <p className="mb-4 text-base sm:text-lg">
            Aussie Rules Madness is an AFL ladder prediction competition where participants can win <span className="font-semibold">$100,000 AUD</span> by predicting the exact final AFL ladder standings for the season.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">2. Eligibility</h2>
          <ul className="list-disc ml-6 mb-4 text-base sm:text-lg">
            <li>Participants must be 18 years or older.</li>
            <li>Participants must reside in Australia.</li>
            <li>Winners will need to provide proof of identification to claim the prize.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">3. How to Enter</h2>
          <ul className="list-disc ml-6 mb-4 text-base sm:text-lg">
            <li>Create an account on the Aussie Rules Madness website.</li>
            <li>Submit your AFL ladder prediction before the lockout begins (the first game of the AFL home-and-away season).</li>
            <li>Each participant is allowed one prediction only. Multiple accounts or entries are not allowed.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">4. Lockout Period</h2>
          <p className="mb-4 text-base sm:text-lg">
            The lockout period begins at the start of the first game of the AFL home-and-away season. During this time, no changes to predictions can be made. The competition ends after the last home-and-away game of the season when the final ladder is determined.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">5. Prize Pool</h2>
          <ul className="list-disc ml-6 mb-4 text-base sm:text-lg">
            <li>The total prize pool is <span className="font-semibold">$100,000 AUD</span>.</li>
            <li>To win, your prediction must match the exact final AFL ladder standings for all 18 teams.</li>
            <li>If there are multiple winners, the prize will be split evenly. For example, two winners will each receive $50,000 AUD.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">6. Verification</h2>
          <ul className="list-disc ml-6 mb-4 text-base sm:text-lg">
            <li>All participants must verify their email and mobile phone number when creating an account.</li>
            <li>Winners must provide proof of identification to confirm their details match the information provided during account registration.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">7. Disqualification</h2>
          <ul className="list-disc ml-6 mb-4 text-base sm:text-lg">
            <li>Participants who create multiple accounts or submit multiple entries will be disqualified.</li>
            <li>Any evidence of fraudulent activity or misrepresentation will result in disqualification.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">8. Important Dates</h2>
          <p className="mb-4 text-base sm:text-lg">
            <span className="font-semibold">Lockout Start:</span> The lockout begins at the first game of the AFL home-and-away season.<br />
            <span className="font-semibold">Competition End:</span> The competition ends when the final AFL ladder standings are determined after the last home-and-away game.
          </p>


          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            9. Terms & Conditions
          </h2>
          <p className="text-base sm:text-lg">
            For more detailed rules and legal information, please refer to our{" "}
            <a href="/terms" className="text-indigo-400 underline">
              Terms and Conditions
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default CompetitionRules;
