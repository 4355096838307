import React, { useState } from "react";
import { Canvas } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";

// Preload the model
useGLTF.preload("/untitled1.glb");

function FootballModel({ rotation }) {
  const { scene } = useGLTF("/untitled1.glb");
  return (
    <primitive
      object={scene}
      scale={[0.075, 0.075, 0.075]} // Scale the football
      position={[0, 0, 0]} // Center the football
      rotation={rotation} // Pass dynamic rotation
    />
  );
}

export default function Football3D() {
  const [rotation, setRotation] = useState([1.57, 1.19, 0]); // Default rotation

  const handleMouseMove = (event) => {
    const rect = event.target.getBoundingClientRect();
    const x = (event.clientX - rect.left) / rect.width - 0.5; // Normalize X (-0.5 to 0.5)
    const y = (event.clientY - rect.top) / rect.height - 0.5; // Normalize Y (-0.5 to 0.5)
    setRotation([1.57, 1.19 + x * 0.5, y * 0.2]); // Adjust football rotation based on cursor
  };

  return (
    <div
      style={{
        width: "250px",
        height: "250px",
      }}
      onMouseMove={handleMouseMove} // Track mouse movement
    >
      <Canvas
        camera={{
          position: [9.3, 0, 26.71], // Set your desired camera position
          fov: 45,
        }}
      >
        <ambientLight intensity={0.5} />
        <directionalLight position={[5, 5, 5]} />
        <FootballModel rotation={rotation} /> {/* Pass rotation */}
      </Canvas>
    </div>
  );
}
