import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { getUserIpAddress } from "../utils/ipUtils"; // Ensure this function is properly imported

function CompleteProfile() {
  const [username, setUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [emailListOptIn, setEmailListOptIn] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;

      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUsername(userData.username || "");
          setFirstName(userData.firstName || "");
          setLastName(userData.lastName || "");
          setDob(userData.dob || "");
          setPhone(userData.phone || "");
          setEmailListOptIn(userData.emailListOptIn || false);

          if (userData.profileComplete) {
            navigate("/my-predictions");
          }
        }
      }
    };

    fetchUserData();
  }, [navigate]);

  const handleSave = async (e) => {
    e.preventDefault();
    setError("");
  
    const user = auth.currentUser;
  
    if (user) {
      try {
        const response = await fetch("http://localhost:2000/api/auth/complete-profile", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId: user.uid,
            username,
            firstName,
            lastName,
            dob,
            phone,
            emailListOptIn,
          }),
        });
  
        const data = await response.json();
  
        if (response.ok) {
          navigate("/my-predictions");
        } else {
          setError(data.error || "Failed to complete profile.");
        }
      } catch (err) {
        console.error("Error saving profile:", err);
        setError("Failed to complete profile. Please try again.");
      }
    }
  };
  

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white px-6">
      <h2 className="text-3xl font-bold mb-4">Complete Your Profile</h2>
      {error && <p className="text-red-500">{error}</p>}
      <form
        className="bg-gray-700 p-6 rounded-lg shadow-lg max-w-md w-full"
        onSubmit={handleSave}
      >
        <div className="mb-4">
          <label className="block text-gray-300 mb-2">
            Username <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 rounded-lg bg-gray-600 text-white"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-300 mb-2">
            First Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 rounded-lg bg-gray-600 text-white"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-300 mb-2">
            Last Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="w-full px-3 py-2 rounded-lg bg-gray-600 text-white"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-300 mb-2">
            Date of Birth <span className="text-red-500">*</span>
          </label>
          <input
            type="date"
            className="w-full px-3 py-2 rounded-lg bg-gray-600 text-white"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-300 mb-2">
            Phone Number <span className="text-red-500">*</span>
          </label>
          <input
            type="tel"
            className="w-full px-3 py-2 rounded-lg bg-gray-600 text-white"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            checked={emailListOptIn}
            onChange={(e) => setEmailListOptIn(e.target.checked)}
          />
          <label className="ml-2 block text-gray-300">
            Add me to the email list
          </label>
        </div>

        <button
          type="submit"
          className="w-full mt-6 bg-indigo-500 text-white py-2 rounded hover:bg-indigo-700 transition-all"
        >
          Save and Continue
        </button>
      </form>
    </div>
  );
}

export default CompleteProfile;
