import React, { useState, useEffect } from "react";
import { useAuth } from "../AuthContext"; // Assuming you have AuthContext set up
import { db } from "../firebase"; // Firestore database
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

function Profile() {
  const { user, logout } = useAuth(); // Get the current user and logout function from AuthContext
  const [profile, setProfile] = useState(null); // Store user profile data
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login if no user is logged in
    if (!user) {
      navigate("/login");
      return;
    }

    // Fetch user profile from Firestore
    const fetchProfile = async () => {
      try {
        const docRef = doc(db, "users", user.uid); // Firestore document for this user
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setProfile(data);
        } else {
          console.log("No user profile found!");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    fetchProfile();
  }, [user, navigate]);

  // Redirect to the Confirm Delete Account page
  const handleDeleteRedirect = () => {
    navigate("/confirm-delete"); // Redirects to the confirmation page
  };

  // Redirect to the Change Password page (for non-Google accounts)
  const handleChangePasswordRedirect = () => {
    navigate("/change-password"); // Redirects to the Change Password page
  };

  if (!profile) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-800 text-white">
        <p>Loading profile...</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white px-6">
      <div className="w-full max-w-md bg-gray-700 p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-4 text-center">My Profile</h1>

        {/* User Information */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold">User Information</h2>
          <p>
            <strong>Username:</strong> {profile.username || "Not set"}
          </p>
          <p>
            <strong>Email:</strong> {profile.email}
          </p>
          <p>
            <strong>Phone:</strong> {profile.phone || "Not set"}
          </p>
        </div>

        {/* Account Actions */}
        <div className="mb-6">
          <h2 className="text-xl font-semibold">Account Settings</h2>
          {user.providerData[0]?.providerId !== "google.com" && (
            <button
              onClick={handleChangePasswordRedirect} // Redirect to Change Password page for non-Google accounts
              className="w-full bg-indigo-500 text-white py-2 mt-2 rounded hover:bg-indigo-700 transition-all"
            >
              Change Password
            </button>
          )}
          <button
            onClick={logout} // Logout the user
            className="w-full bg-red-500 text-white py-2 mt-2 rounded hover:bg-red-700 transition-all"
          >
            Logout
          </button>
          <button
            onClick={handleDeleteRedirect} // Redirect to Confirm Delete page
            className="w-full bg-red-700 text-white py-2 mt-2 rounded hover:bg-red-900 transition-all"
          >
            Delete Account
          </button>
        </div>

        {/* Prediction Summary */}
        <div>
          <h2 className="text-xl font-semibold">My Predictions</h2>
          <p className="mt-2 text-gray-300">No predictions made yet.</p>
        </div>
      </div>
    </div>
  );
}

export default Profile;
