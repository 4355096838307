import React from "react";

function PrivacyPolicy() {
  return (
    <div className="pt-20 min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-indigo-900 via-gray-800 to-gray-900 text-white">
      <div className="bg-gray-800 shadow-lg rounded-lg p-6 sm:p-8 max-w-4xl w-full">
        <h1 className="text-3xl sm:text-4xl font-bold text-center mb-6">
          Privacy Policy
        </h1>

        <section className="space-y-6 text-sm sm:text-base">
          <p>
            At <span className="font-semibold">Aussie Rules Madness</span>, we
            are committed to protecting your privacy and ensuring your data is
            handled securely and transparently. This Privacy Policy outlines
            how we collect, use, store, and share your data, as well as your
            rights.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            1. Company Details
          </h2>
          <p>
            <span className="font-semibold">Company Name:</span> Aussie Rules
            Madness
          </p>
          <p>
            <span className="font-semibold">Contact Email Address:</span>{" "}
            support@aussierulesmadness.com
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            2. Information We Collect
          </h2>
          <h3 className="text-lg font-semibold mt-4">2.1 Personal Data</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Full Name: For account creation and identification.</li>
            <li>Email Address: To enable login and communicate updates.</li>
            <li>Date of Birth: To verify eligibility (16+).</li>
            <li>
              IP Address: To detect and prevent multiple accounts or fraudulent
              activity.
            </li>
            <li>Mobile Phone Number: For security and account recovery.</li>
            <li>
              Google Account Information: If signing in via Google, we collect
              your name, email, and profile image.
            </li>
          </ul>
          <h3 className="text-lg font-semibold mt-4">2.2 Non-Personal Data</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Browser and device details.</li>
            <li>Usage statistics, including pages visited and session duration.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            3. How We Use Your Data
          </h2>
          <h3 className="text-lg font-semibold mt-4">3.1 Purpose of Collection</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Verify eligibility for participation (must be 16+).</li>
            <li>Manage accounts and provide login functionality.</li>
            <li>Detect and prevent fraud or multiple accounts.</li>
            <li>Communicate contest updates and other important information.</li>
            <li>Improve the Service based on usage trends.</li>
          </ul>
          <h3 className="text-lg font-semibold mt-4">3.2 Data Usage</h3>
          <p>
            Your data is securely stored and used solely for the purposes
            outlined above. Sensitive data, such as passwords, is encrypted
            during storage and transmission.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            4. Data Sharing
          </h2>
          <h3 className="text-lg font-semibold mt-4">4.1 Who We Share Data With</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Firebase: For secure data storage and hosting.</li>
            <li>Google Analytics: For tracking user behavior and improving the Service.</li>
            <li>
              Australian Authorities: If required by law, such as during fraud
              investigations.
            </li>
          </ul>
          <h3 className="text-lg font-semibold mt-4">4.2 Sale of Data</h3>
          <p>We do not sell personal data to advertisers or other third parties.</p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            5. Data Storage
          </h2>
          <h3 className="text-lg font-semibold mt-4">5.1 Storage Location</h3>
          <p>Your data is stored securely on Firebase servers located in Australia.</p>
          <h3 className="text-lg font-semibold mt-4">5.2 Backup Policies</h3>
          <p>We use Firebase’s default backup and restoration processes to ensure data security and availability.</p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            6. Data Retention
          </h2>
          <h3 className="text-lg font-semibold mt-4">6.1 Retention Period</h3>
          <p>
            We retain personal data until the contest for the current season
            ends or until users delete their accounts.
          </p>
          <h3 className="text-lg font-semibold mt-4">6.2 Post-Retention Actions</h3>
          <p>
            After the retention period:
            <ul className="list-disc ml-6 space-y-2">
              <li>Data is permanently deleted, or</li>
              <li>Non-identifiable data may be anonymized for analytics purposes.</li>
            </ul>
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            7. Data Security
          </h2>
          <h3 className="text-lg font-semibold mt-4">7.1 Measures in Place</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Encryption of sensitive information (e.g., passwords).</li>
            <li>HTTPS for secure data transmission.</li>
            <li>Firewalls and monitoring tools to detect and prevent breaches.</li>
          </ul>
          <h3 className="text-lg font-semibold mt-4">7.2 Access Control</h3>
          <p>Only admin-level personnel with specific roles have access to your data.</p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            8. Notification of Changes
          </h2>
          <p>
            If we update this Privacy Policy, we will notify users via:
            <ul className="list-disc ml-6 space-y-2">
              <li>Email</li>
              <li>A banner announcement on the website</li>
            </ul>
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            9. Cookies and Tracking
          </h2>
          <h3 className="text-lg font-semibold mt-4">9.1 Types of Cookies Used</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Essential Cookies: Required for account login and basic functionality.</li>
            <li>Analytics Cookies: To understand user behavior and improve the website.</li>
          </ul>
          <h3 className="text-lg font-semibold mt-4">9.2 User Options</h3>
          <p>Users cannot opt out of cookies as they are essential for the Service.</p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            10. Privacy Compliance
          </h2>
          <h3 className="text-lg font-semibold mt-4">10.1 Australian Privacy Principles (APP)</h3>
          <p>
            Aussie Rules Madness complies with the APP framework to protect
            user data and ensure transparency.
          </p>
          <h3 className="text-lg font-semibold mt-4">10.2 Data Breach Notification</h3>
          <p>
            In the event of a data breach, users will be notified promptly via email.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            11. Children’s Privacy
          </h2>
          <h3 className="text-lg font-semibold mt-4">11.1 Age Requirement</h3>
          <p>Participation is restricted to users aged 16 or older.</p>
          <h3 className="text-lg font-semibold mt-4">11.2 Age Verification</h3>
          <p>
            We verify age through the date of birth provided during account
            creation. Winners will be required to submit valid identification
            to confirm eligibility.
          </p>
          <h3 className="text-lg font-semibold mt-4">11.3 Handling Underage Accounts</h3>
          <p>
            If an account is found to belong to someone under 16:
            <ul className="list-disc ml-6 space-y-2">
              <li>The account and associated data will be deleted immediately.</li>
            </ul>
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            12. User Rights
          </h2>
          <h3 className="text-lg font-semibold mt-4">12.1 Data Access and Deletion</h3>
          <p>
            Users can manage their data by:
            <ul className="list-disc ml-6 space-y-2">
              <li>Deleting their account through the website.</li>
              <li>
                If additional data access or correction is required, users may
                email support@aussierulesmadness.com.
              </li>
            </ul>
          </p>
          <h3 className="text-lg font-semibold mt-4">12.2 Response Time</h3>
          <p>We will respond to all data-related requests within 30 days.</p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            13. Contact Information
          </h2>
          <p>
            For questions or concerns about this Privacy Policy, please
            contact:
            <ul className="list-disc ml-6 space-y-2">
              <li>Email: support@aussierulesmadness.com</li>
            </ul>
          </p>
        </section>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
