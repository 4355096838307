import React, { useState } from "react";
import { getAuth, sendEmailVerification } from "firebase/auth";

function VerifyEmail() {
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const handleResendVerificationEmail = async () => {
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
        setMessage("Verification email sent! Please check your inbox.");
      } else {
        setError("You must be logged in to resend the verification email.");
      }
    } catch (error) {
      console.error("Error resending verification email:", error);
      setError("Error resending verification email: " + error.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white px-4 sm:px-6 md:px-8">
      <div className="bg-gray-700 p-6 sm:p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl sm:text-3xl font-bold mb-4 text-center">Email Verification</h2>
        {message && (
          <p className="text-green-500 text-center mb-4">{message}</p>
        )}
        {error && <p className="text-red-500 text-center mb-4">{error}</p>}
        <button
          onClick={handleResendVerificationEmail}
          className="bg-indigo-500 text-white w-full px-4 py-2 sm:py-3 rounded-lg mt-4 hover:bg-indigo-700 transition-all"
        >
          Resend Verification Email
        </button>
      </div>
    </div>
  );
}

export default VerifyEmail;
