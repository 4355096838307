import React from "react";

function TermsAndConditions() {
  return (
    <div className="pt-20 min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-indigo-900 via-gray-800 to-gray-900 text-white">
      <div className="bg-gray-800 shadow-lg rounded-lg p-6 sm:p-8 max-w-4xl w-full">
        <h1 className="text-3xl sm:text-4xl font-bold text-center mb-6">
          Terms and Conditions
        </h1>

        <section className="space-y-6 text-sm sm:text-base">
          <p>
            Welcome to <span className="font-semibold">Aussie Rules Madness</span>! These Terms and Conditions ("Terms") govern your use of the Aussie Rules Madness website ("Website"), located at{" "}
            <span className="text-indigo-400">www.aussierulesmadness.com</span>.
            By accessing or using our Website, you agree to comply with and be
            bound by these Terms. If you do not agree to these Terms, please do
            not use our Website.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            1. General Information
          </h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              Aussie Rules Madness is an AFL ladder prediction contest where participants compete to predict the exact final standings of the AFL ladder at the conclusion of the home-and-away season.
            </li>
            <li>The contest is targeted at Australian residents only.</li>
            <li>Participants must be 18 years or older to enter.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            2. Contest Details
          </h2>

          <h3 className="font-semibold text-lg mt-4">Entry Cost:</h3>
          <p>
            Participation in the contest is completely free. No purchase or payment is required to enter.
          </p>

          <h3 className="font-semibold text-lg mt-4">Guesses:</h3>
          <p>
            Each participant is allowed <span className="font-semibold">one guess only</span>. Creating multiple accounts or submitting multiple entries is strictly prohibited.
          </p>

          <h3 className="font-semibold text-lg mt-4">Prize Pool:</h3>
          <p>
            The total prize pool is <span className="font-semibold">$100,000 AUD</span>.
          </p>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              <span className="font-semibold">Winning Criteria:</span> To win the prize, participants must predict the AFL ladder perfectly, placing all 18 teams in the correct order as they appear in the final AFL ladder at the conclusion of the home-and-away season.
            </li>
            <li>
              <span className="font-semibold">Tie-Breaker:</span> If multiple participants submit correct predictions, the prize pool will be divided equally among all winners. For example, if two participants have perfect predictions, each will receive $50,000 AUD.
            </li>
          </ul>

          <h3 className="font-semibold text-lg mt-4">Eligibility:</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>Participants must be living in Australia.</li>
            <li>
              Proof of identification (e.g., driver's license or passport) will be required to confirm residency and age if declared a winner.
            </li>
            <li>
              Identification documents must match the details provided during account registration, including family name, first name, and date of birth.
            </li>
            <li>
              Identification will only be requested if the participant is declared a winner. This policy is in place to minimize concerns about data security.
            </li>
          </ul>

          <h3 className="font-semibold text-lg mt-4">Lockout Period:</h3>
          <p>
            Participants may update their predictions up until the <span className="font-semibold">first game of the AFL home-and-away season</span> (opening round). Once the first game begins, all predictions will be locked, and no further changes will be permitted for the current season.
          </p>
          <p>
            The competition ends when the final AFL ladder is determined at the conclusion of the last home-and-away game of the season. The lockout period remains in effect until the start of the next season.
          </p>

          <h3 className="font-semibold text-lg mt-4">Verification:</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              Participants must verify both their email address and mobile phone number during account registration.
            </li>
            <li>This ensures that each participant only uses one account and helps prevent fraudulent entries.</li>
          </ul>

          <h3 className="font-semibold text-lg mt-4">Disqualification:</h3>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              Participants will be disqualified from winning the prize if they are found to have created multiple accounts using the same or similar details (e.g., identical names, dates of birth).
            </li>
            <li>
              Evidence of fraudulent activity, misrepresentation, or other actions deemed against the contest rules will also result in disqualification.
            </li>
          </ul>

          <h3 className="font-semibold text-lg mt-4">Age Restriction:</h3>
          <p>
            Participants must be <span className="font-semibold">18 years or older</span> to enter the contest.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            3. Account Creation
          </h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>You are responsible for providing accurate and up-to-date information when creating your account.</li>
            <li>You are responsible for maintaining the confidentiality of your account credentials. Aussie Rules Madness will not be liable for any unauthorized access to your account.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            4. Intellectual Property
          </h2>
          <p>
            All content on this Website, including text, images, logos, and
            designs, is the property of Aussie Rules Madness or its licensors
            and is protected by intellectual property laws. You may not
            reproduce, distribute, or use any content from this Website without
            prior written permission.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            5. Limitation of Liability
          </h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>Aussie Rules Madness is not responsible for any technical issues, website downtime, or errors that may prevent you from submitting your prediction.</li>
            <li>By participating in the competition, you acknowledge that your participation is at your own risk.</li>
            <li>In the event of disputes regarding the competition or its outcome, Aussie Rules Madness reserves the right to make the final decision.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            6. Prize Distribution
          </h2>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              The prize pool of <span className="font-semibold">$100,000 AUD</span> will be awarded to the user(s) whose prediction exactly matches the final AFL ladder at the end of the season.
            </li>
            <li>
              In the event of multiple correct predictions, the prize will be
              split evenly among the winners.
            </li>
            <li>Aussie Rules Madness reserves the right to verify the eligibility and identity of any winner(s) before distributing the prize.</li>
          </ul>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            7. Privacy Policy
          </h2>
          <p>
            By using this Website, you agree to the collection and use of your
            personal information as outlined in our Privacy Policy. Your
            personal information will only be used for account management,
            competition entry, and related purposes.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            8. Changes to Terms
          </h2>
          <p>
            Aussie Rules Madness reserves the right to update these Terms at
            any time. Changes will be effective immediately upon posting on the
            Website. It is your responsibility to review the Terms periodically
            to stay informed of any updates.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            9. Governing Law
          </h2>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of Australia. Any disputes arising from or in connection with these Terms shall
            be subject to the exclusive jurisdiction of Australian courts.
          </p>

          <h2 className="text-xl sm:text-2xl font-semibold mt-6">
            10. Contact Us
          </h2>
          <p>If you have any questions about these Terms, please contact us at:</p>
          <ul className="list-disc ml-6 space-y-2">
            <li>
              Email: <span className="text-indigo-400">[Your Email Address]</span>
            </li>
            <li>
              Address:{" "}
              <span className="text-indigo-400">[Your Business Address]</span>
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}

export default TermsAndConditions;
