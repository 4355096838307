// ProtectedRoute.js
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useAuth } from "./AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "./firebase";

const ProtectedRoute = ({ children }) => {
  const { user, isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [profileComplete, setProfileComplete] = useState(false);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (user) {
        try {
          await user.reload(); // Ensure user data is updated (e.g., emailVerified status)
          setIsVerified(user.emailVerified);

          // Fetch user data from Firestore
          const userDocRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            const userData = userDoc.data();
            setProfileComplete(userData.profileComplete || false);
          } else {
            // If the user document doesn't exist, consider the profile incomplete
            setProfileComplete(false);
          }
        } catch (error) {
          console.error("Error reloading user data:", error);
        }
      }
      setLoading(false); // End loading state once user status is determined
    };

    checkUserStatus();
  }, [user]);

  // Render a loading state while checking user authentication and verification
  if (loading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white">
        <p className="text-xl font-bold">Loading...</p>
      </div>
    );
  }

  // Redirect to the login page if the user is not logged in
  if (!isLoggedIn) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white px-6">
        <h2 className="text-2xl font-bold mb-4">You need to sign in to access this page</h2>
        <div className="space-x-4">
          <Link
            to="/auth"
            className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-700 transition-all"
          >
            Sign In
          </Link>
        </div>
      </div>
    );
  }

  // Redirect to the email verification page if the user hasn't verified their email
  if (user && !isVerified) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white px-6">
        <h2 className="text-2xl font-bold mb-4">
          Please verify your email to access this page.
        </h2>
        <button
          className="bg-indigo-500 text-white px-4 py-2 rounded mt-4 hover:bg-indigo-700"
          onClick={() => navigate("/verify-email")}
        >
          Go to Email Verification
        </button>
      </div>
    );
  }

  // Redirect to complete profile page if the user's profile is incomplete
  if (user && !profileComplete) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white px-6">
        <h2 className="text-2xl font-bold mb-4">
          Please complete your profile to access this page.
        </h2>
        <button
          className="bg-indigo-500 text-white px-4 py-2 rounded mt-4 hover:bg-indigo-700"
          onClick={() => navigate("/complete-profile")}
        >
          Complete Profile
        </button>
      </div>
    );
  }

  // If all conditions are met, render the protected content
  return children;
};

export default ProtectedRoute;
